import { GET_VIDEO } from "./VideoType"
const initialState = {
    video: []
}

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VIDEO: return {
            ...state,
            video: { ...state.video, ...action.payload }
        }
    }
}
export default videoReducer