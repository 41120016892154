import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function AwardsPage(props) {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 250,
        autoplaySpeed: 2000,
        cssEase: "linear",
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    }

    useEffect(() => {
        getAwards()
    }, [])

    const [awards, setAwards] = useState([])

    const getAwards = () => {
        axios.get(`${props.api}/api/content/items/awards`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                setAwards(res.data)               
            })
            .catch(error => {
                console.log(error)
            })
    }

    const savedAwards = awards.map(award => (
        <div key={award._id} className='award'>
            <img src={`${props.api}/storage/uploads${award.image.path}`} className='awards-page-pic mt-4' />
        </div>
    ))


    return (
        <div className='awardsPage container-fluid'>
            <h1 className='secHeading'>AWARDS</h1>
            <div className='awards-slider'>
                <Slider {...settings}>
                    {savedAwards}
                </Slider>
            </div>
        </div>
    )
}

export default AwardsPage