import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from './img/logo.png'
import email from './img/email.svg'
import facebook from './img/facebook.svg'
import instagram from './img/instagram.svg'
import menulogo from './img/menubtn.svg'
import closebtn from './img/closebtn.svg'



function Menu() {

    const navHandler = (status) => {      
        handleNavStatus(`mobile-menu-list ${status}`) 
    }
    
    const [navStatus, handleNavStatus] = useState('mobile-menu-list hide')


    return (
        <div className='app-menu container-fluid'>
            <div className='row pt-3 align-items-center'>
                <div className='col-md-3 col-6'>
                    <Link to='/'> <img className='img-fluid desktop-logo' src={logo} alt='willsworld logo' /> </Link>
                </div>
                <div className='col-6 mobile-menu'>
                    <img className='img-fluid mobile-menu-btn' src={menulogo} alt='menu btn' onClick={() => navHandler('show')} />
                </div>
                <div className='col-md-9 col-6'>
                    <div className='desktop-socials-menu-list' >
                        <ul>
                            <li> <a href='https://www.facebook.com/WillsworldProduction'> <img className='social-header-img' src={facebook} alt='facebook logo' /> </a> </li>
                            <li> <a href='https://www.instagram.com/willsworldproduction/?hl=en'> <img className='social-header-img' src={instagram} alt='instagram logo' /> </a> </li>
                            <li> <a href='mailto:willsworldproduction@hotmail.com'> <img className='social-header-img' src={email} alt='email logo' /> </a> </li>
                        </ul>
                    </div>
                    <div className='desktop-menu-list'>
                        <ul>
                            <Link to='/reels'> <li>Reels</li> </Link>
                            <Link to='/services'> <li>Services</li> </Link>
                            <Link to='/awards'> <li>Awards</li> </Link>
                            <Link to='/news'> <li>News</li> </Link>
                            <Link to='/about'> <li>About</li> </Link>
                            <Link to='/work-with-us'> <li>Work With Us</li> </Link>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={navStatus}>
                <div className='row align-items-center'>
                <div className='col-6'>
                    <Link to='/'> <img className='img-fluid desktop-logo' src={logo} alt='willsworld logo' /> </Link>
                </div>
                <div className='col-6 mobile-menu'>
                    <img className='img-fluid close-mobile-menu-btn' src={closebtn} alt='menu btn' onClick={() => navHandler('hide')} />
                </div>
                </div>
                <ul>
                    <Link to='/reels' onClick={() => navHandler('hide')}> <li>Reels</li> </Link>
                    <Link to='/services' onClick={() => navHandler('hide')}>  <li>Services</li> </Link>
                    <Link to='/awards' onClick={() => navHandler('hide')}> <li>Awards</li> </Link>
                    <Link to='/news' onClick={() => navHandler('hide')}> <li>News</li> </Link>
                    <Link to='/about' onClick={() => navHandler('hide')}> <li>About</li> </Link>
                    <Link to='/work-with-us' onClick={() => navHandler('hide')}> <li>Work With Us</li> </Link>
                </ul>
            </div>
        </div>
    )
}

export default Menu