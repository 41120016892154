import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

function Article(props) {

    const params = useParams()

    useEffect(() => {
        getArticles()
    }, [])

    const [article, setArticle] = useState([])
    const [articlePath, setArticlePath] = useState(null)
    const [found, setFound] = useState(true)

    const getArticles = () => {
        axios.get(`${props.api}/api/content/items/news`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                let foundArticle = res.data.find(slug => slug.slug === params.id)
                let articlePath = foundArticle.image.path
                setArticle(foundArticle)
                setArticlePath(articlePath)
                // console.log(foundArticle)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const showArticle = 
        <div className='row'>
            <div className='col-md-5 col-12 news-article'>
                <img src={`${props.api}/storage/uploads${articlePath}`} className='home-news-pic' />
            </div>
            <div className='col-md-7 col-12 news-article'>
                <h3 className='news-header'>{article.heading}</h3>
                <div className='news-content' dangerouslySetInnerHTML={{ __html: article.content }}></div>
            </div>
        </div>
    

    // const allArticles = articles.map(article => (
    //     <div key={article._id} className='sinArticle mb-4'>
    //         <div className='col-12'>
    //             <img src={`${props.api}/storage/uploads${article.image.path}`} className='home-news-pic' />
    //         </div>
    //         <div className='col-12'>
    //             <h4 className='news-header mt-2'>{article.heading}</h4>
    //             <p>{article.excerpt}</p>
    //             <div className='home-read-more-btn text-center'>
    //                 <button type='button' type='submit' className='btn btn-contact-message'>READ MORE</button>
    //             </div>
    //         </div>
    //     </div>
    // ))

    return (
        <div className='article container-fluid'>
            {showArticle}
        </div>
    )
}

export default Article