import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getVideo } from './redux/Index'
import axios from 'axios'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Modal from 'react-modal'
import PlayVideo from './PlayVideo'


function ReelsPage(props) {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 250,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            }
        ]
    }

    useEffect(() => {
        getReels()
    }, [])

    const [reels, setReels] = useState([])
    const [modalState, setModalState] = useState(false)
    const [vid, setVid] = useState([])

    const getReels = () => {
        axios.get(`${props.api}/api/content/items/showreel`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                setReels(res.data)
                console.log(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    // const showReels = reels.map(reel => (
    //     <div key={reel._id} className='col-md col-6 reel-single'>
    //         <img src={`${props.api}/storage/uploads${reel.thumb.path}`} className='reel-thumb' />
    //         <div className='reel-details'>
    //             <h1>{reel.title}</h1>
    //             <h2>Director: {reel.Director}</h2>
    //         </div>
    //     </div>
    // )).reverse().slice(0, 5)

    const handleOpenModal = (title, slug) => {
        setModalState(true)
        const vid = {
            vid: {
                video: title,
                slug: slug
            }
        }
        props.getVideo(vid)
    }

    const handleCloseModal = () => {
        setModalState(false)
    }

    const showReels = reels.map(reel => (
        <div key={reel._id} className='reel-single'>
            {reel.thumb.path ?
                <img src={`${props.api}/storage/uploads${reel.thumb.path}`} className='reel-thumb' onClick={() => handleOpenModal(reel.title, reel.video.path)} />
                : null}
            <div className='reel-details'>
                {reel.title ? <h1>{reel.title}</h1> : null}
                {reel.Director ? <h2>Director: {reel.Director}</h2> : null}
            </div>
        </div>
    )).reverse()



    return (
        <div className='container-fluid' id='showreel'>
            <h1 className='secHeading'><span className='lights'>LIGHTS!</span>  <span className='lights'>CAMERA!</span> <span className='lights'> ACTION!</span></h1>
            <div className='mt-2 reel-wrapper'>
                <Slider {...settings}>
                    {showReels}
                </Slider>
            </div>
            <Modal
                isOpen={modalState}
                closeTimeoutMS={200}
                contentLabel="modLabel"
                onRequestClose={handleCloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <PlayVideo video={props.video} api={props.api} />

                {/* <div className='ModalClose'>
                    <Image src={close} onClick={this.handleCloseModal} alt='close button' />
                </div> */}
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        video: state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getVideo: (...args) => dispatch(getVideo(...args)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReelsPage)