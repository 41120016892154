import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

function News(props) {

    useEffect(() => {
        getAwards()
    }, [])

    const [articles, setArticles] = useState([])

    const getAwards = () => {
        axios.get(`${props.api}/api/content/items/news`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                setArticles(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const showArticle = articles.map(article => (
        <div key={article._id} className='row'>
            <div className='col-md-5 col-12'>
                <img src={`${props.api}/storage/uploads${article.image.path}`} className='home-news-pic' />
            </div>
            <div className='col-md-7 col-12'>
                <h3 className='news-header'>{article.heading}</h3>
                <div className='news-content' dangerouslySetInnerHTML={{ __html: article.content }}></div>
                <div className='home-read-more-btn'>
                    <Link to={`/news/${article.slug}`}>
                        <button type='button' className='btn btn-contact-message'>READ MORE</button>
                    </Link>
                </div>
            </div>
        </div>
    )).reverse().slice(0, 1)

    return (
        <div className='container-fluid news-wrapper'>
            {showArticle}
        </div>
    )
}

export default News