import axios from 'axios'
import React, { useEffect, useState } from 'react'
import useWindowDimensions from './WindowDimensions'

function About(props) {


  useEffect(() => {
    getAbout()
  }, [])

  const [about, setAbout] = useState([])
  const { height, width } = useWindowDimensions()

  const getAbout = () => {
    axios.get(`${props.api}/api/content/item/about`, {
      headers: {
        "Content-Type": "application/json",
        'api-key': props.acctok,
        'Cache-Control': 'no-cache',
      }
    })
      .then(res => {
        setAbout(res.data.content)        
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div className='aboutPage container-fluid'>
      <h1 className='secHeading'>ABOUT</h1>
      <div className='about-content' dangerouslySetInnerHTML={{__html: about}}></div>
    </div>
  )
}

export default About