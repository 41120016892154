import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import axios from 'axios'
import logo from './img/logo.png'
import About from './About'
import Article from './Article'
import AwardsPage from './AwardsPage'
import Contact from './Contact'
import DesktopHome from './DesktopHome'
import Footer from './Footer'
import Menu from './Menu'
import NewsPage from './NewsPage'
import ReelsPage from './ReelsPage'
import Services from './Services'
import NotFound from './NotFound'

export class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            api: 'https://cms.willsworldproduction.com',
            // api: 'http://localhost',
            acctok: 'API-9e710685399a8e263ea03a6f96e72cec9d11e4fa', 
            maintenence: []           
        }
    }

    componentDidMount() {
        axios.get(`${this.state.api}/api/content/item/maintenance`, {
            headers: {
              "Content-Type": "application/json",
              'api-key': this.state.acctok,
              'Cache-Control': 'no-cache',
            }
          })
            .then(res => {
              this.setState({maintenence: res.data})       
            })
            .catch(error => {
              console.log(error)
            })
    }

    render() {
        if (this.state.maintenence.maintenance) {
           return(
               <div className='app-container maintenence text-center'>
                   <img src={logo} className='img-fluid' />
                   <img src={`${this.state.api}/storage/uploads/${this.state.maintenence.image.path}`} />
                   <div className='maintenence-content' dangerouslySetInnerHTML={{__html: this.state.maintenence.message}}></div>
               </div>
           )
        } else {
            return (
                <Router>
                    <Menu />
                    <div className='app-container'>
                        <Routes>
                            <Route exact path='/' element={<DesktopHome api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/reels' element={<ReelsPage api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/services' element={<Services api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/awards' element={<AwardsPage api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/news' element={<NewsPage api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/news/:id' element={<Article api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/about' element={<About api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/work-with-us' element={<Contact api={this.state.api} acctok={this.state.acctok} />} />
                            <Route path='*' element={<NotFound/>} />
                        </Routes>
                        <Footer api={this.state.api} acctok={this.state.acctok} />
                    </div>
                </Router>
            )
        }
        
    }
}

export default Home