import React from 'react'
import Awards from './Awards'
import HomePanel from './HomePanel'
import News from './News'
import ReelHighlight from './ReelHighlight'
import WorkWithUs from './WorkWithUs'

function DesktopHome(props) {
    return (
        <>
            <HomePanel api={props.api} acctok={props.acctok} />
            <ReelHighlight api={props.api} acctok={props.acctok} />       
            <WorkWithUs api={props.api} acctok={props.acctok} />
            <Awards api={props.api} acctok={props.acctok} />
            <News api={props.api} acctok={props.acctok} />
        </>
    )
}

export default DesktopHome