import React from 'react'
import ReactPlayer from 'react-player'

function PlayVideo(props) {    
    const video = `${props.api}/storage/uploads/${props.video.video.vid.slug}`
    // const video = `https://willcms.buzzolution.com/storage/uploads//2022/12/08/Willsworld-Production-Home-Page-Intro-REDO-converted_uid_63924c5f7abb7.mp4`
    
    return (
        <div className='video-wrapper'>
            <h1 className='secHeading'>{props.video.video.vid.video}</h1>
            {/* <ReactPlayer
                url={[{ src: video }]}
                // url= {video}
                controls={true}
                volume={0}
                muted={true}
            /> */}
            <video preload='auto' style={{width: '100%', height: '100%'}} className='reel-vid' controls>
                <source src={video} />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default PlayVideo