import React from 'react'
import { Link } from 'react-router-dom'
import notfound from './img/404.svg'

function NotFound() {
    return (
        <div className='text-center mx-4 not_found'>
            <img className='img-fluid mb-4' src={notfound} alt='page not found' />
            <h1>404 - Not Found :(</h1>
            <h2>The page you looking for was not found. Let's go <Link to='./'>home</Link></h2>
        </div>
    )
}

export default NotFound